import * as Realm from 'realm-web'
import { getRealm } from '../setup'
// call backend function

export const callFunc = async <T>(name: string, ...params: any): Promise<any> => {
  const realm = getRealm()

  if (!realm.currentUser) {
    await realm.logIn(Realm.Credentials.anonymous())
  }

  try {
    // console.log('callFunc', 'realm.currentUser', realm.currentUser)
    const res = await realm.currentUser.callFunction(name, ...params)
    // console.log('callfunc done!', name, res)
    return res
  }
  catch (err: any) {
    console.error('callFunc-err?', err, err.errorCode)
    if (err.errorCode === 'InvalidSession' || err.message.indexOf('invalid session') > -1) {
      console.log('InvalidSession, logging out...')
      if (!document.location.href.endsWith('/logout')) {
        document.location.href = '/logout'
      } else {
        throw err
      }
      return
    }
    // console.log(Object.keys(err), err.error);
    if (!err?.error) {
      console.error('unknown error', err)
      throw err
    }
    const errorData = JSON.parse(err?.error)
    // const code = err.
    console.error('realm func error', name, errorData)
    throw new Error(errorData.message)
  }
}

export const callWithNotice = async (
  store: any,
  name: string, params: any,
) => {
  try {
    const res = await callFunc(name, ...params)
    return res
  }
  catch (err: any) {
    store.dispatch('alert/error', err.message)
    throw err
  }
}
